import '../App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import { toastFunction, dateRangeForSearh, executeDefaultReport } from './mainFunctions'; 
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SelectGIE from './SelectGIE';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import PropTypes from 'prop-types';

const DefaultModalReport = ({ajaxUrl, setState}) => {
    const [isOpenModal, setIsOpenModal] = useState(true);
    const [startDate, setStartDate] = useState(moment().add());
    const [endDate, setEndDate] = useState(moment().add());
    const [reportUrl] = useState(ajaxUrl) 
    useEffect(()=> {
        setIsOpenModal(true);
    },[])

    let buttons = [{text: i18n.translate("default_accept"), function: ()=>{executeReport()}},
        {text: i18n.translate("cancel"),function:""} 
    ];

    const handleCallback = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    }; 

    const executeReport = () => {
        executeDefaultReport(dateRangeForSearh(startDate),dateRangeForSearh(endDate),setState,reportUrl,'reporte_de_factura');
    } 

    return(
        <Modal isOpen={isOpenModal} 
        modalTransition={{ timeout: 0 }} backdropTransition={{ timeout: 0 }}>
            <ModalHeader className='modalHeader'>
                {i18n.translate("generate_report")} 
                <button className='close' data-dismiss="close" onClick={()=>setIsOpenModal(false)}>x</button>
            </ModalHeader>
            <ModalBody className={'modalBody'}>
                <div className='form-group row'>
                    <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                        <b>{i18n.translate("default_type")}: </b>
                    </div>
                    <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                        <SelectGIE modal={'true'} modalReport={true}  allUsers={true} hideLabel={'true'} />
                    </div>
                    {' '}
                    <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4' style={{marginTop:'15px'}}>
                        <b>{i18n.translate("date")}: </b>
                    </div>
                    <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8' style={{marginTop:'15px'}}>
                        <DateRangePicker
                            initialSettings={{ startDate: startDate, endDate: endDate }}
                            onCallback={handleCallback}>
                            <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                        </DateRangePicker>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                {buttons.map((b,i) =>{
                    return (
                        <button key={i} className={i != 0 ? 'buttonMzateSecondary' : 'buttonMzatePrimary'}
                        onClick={b.function != '' ? b.function : ()=>setIsOpenModal(false)}>{b.text}</button> 
                    )
                })} 
            </ModalFooter>
        </Modal>
    )
};

DefaultModalReport.propTypes = {
    ajaxUrl: PropTypes.string.isRequired,
    setState: PropTypes.any.isRequired,
};

export default DefaultModalReport;