import { toast } from 'react-toastify';
import { DefaultAjax, modalForbidden } from './DefaultAjax'; 
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import { Table } from 'reactstrap';
import {  DynamicMap, ArrayString, IntervalDayAndHour, Boolean, ArrayOfMap, DynamicSelectedValues } from "../pages/templateConfigs/TemplateConfigs";
import DynamicMapTemplate from "../pages/templateConfigs/DynamicMapTemplate";
import ArrayTemplate from "../pages/templateConfigs/ArrayTemplate";
import IntervalDayAndHourTemplate from "../pages/templateConfigs/IntervalDayAndHourTemplate";
import BooleanTemplate from "../pages/templateConfigs/BooleanTemplate";
import ArrayOfMapTemplate from "../pages/templateConfigs/ArrayOfMapTemplate";
import SelectGIE from "./SelectGIE";
import Select from 'react-select';
import DynamicSelectedValuesTemplate from "../pages/templateConfigs/DynamicSelectedValuesTemplate";
import DefaultSpinner from './DefaultSpinner';

export const windowHistoryReplaceState = (independent,instanceType) => {
    if(!independent || !instanceType){
        independent = document.querySelector("#independent").value;
        instanceType = document.querySelector("#instanceType").value;
    } 
    if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
        window.history.replaceState(null,document.title,window.location.origin + "/" + '?independent='+independent+'?instanceType='+instanceType+'?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
    }
} 

export const dateRangeForSearh = (date) => {
    let startDate = new Date(date);
    let m = startDate.getMonth() + 1;
    let mes = (m < 10) ? '0' + m : m;
    let day = startDate.getDate();
    let dayForShow = (day < 10) ? '0' + day : day;
    startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
    return startDate;
} 

export function handleDateRangeCallback(start, end, setState) {
    const dateRange = []; 
    document.querySelector("#dateInInputStart").value = dateRangeForSearh(start._d);
    document.querySelector("#dateInInputEnd").value = dateRangeForSearh(end._d);
    dateRange.push(dateRangeForSearh(start._d));
    dateRange.push(dateRangeForSearh(end._d));
    setState({
        dateRange: dateRange,
        startDate: start._d,
        endDate: end._d
    });
}

export const redirectToPlatform = (platformUrl,pathRoot,token,html,module) => {
	window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/"+html+"?lang=es?module="+module;
}

export const toastFunction = (message,type) => {
    if(type == "success"){
        return toast.success(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }else if(type == "error"){
        return toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }else if(type == "warning"){
        return toast.warning(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } 
    
}

export const catchErrorAjax = (error,setState) => { 
    setState({
        modalContent:"",
        spinner:"",
        component:error.message
    });
    if (error.response) {
        if(error.response.status === 403){
            setTimeout(()=> { 
                setState({modalContent:modalForbidden()});
            }, 10);
        } 
    }
}

export const executeDefaultReport = (startDate, endDate, setState, url, nameOfDocument) => {
    if(document.querySelector(".modalBody .selectType").value === ''){
        toastFunction(i18n.translate("select_org_node"),"warning");
    }else{
        let model = {
            dateStart: startDate,
            dateEnd: endDate,
            type: document.querySelector(".modalBody .selectType").value,
            user: document.querySelector(".modalBody #userSelected").value,
            gon: document.querySelector(".modalBody #groupSelected").value,
            ion: document.querySelector(".modalBody #profileSelected").value
        } 
        if(document.querySelector(".modalBody .selectType").value === "1" && model.ion === ''){
            toastFunction(i18n.translate("select_org_node"),"warning");
        }else if(document.querySelector(".modalBody .selectType").value === "2" && model.gon === ''){
            toastFunction(i18n.translate("select_org_node"),"warning");
        }else{
            setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
                independent={document.querySelector("#independent").value}/>})
            DefaultAjax('', 'blob').get(url+'?reportDtoJson='+encodeURI(JSON.stringify(model)))
            .then((res) => {
                setState({
                    spinner:""
                });  
                if(res.status === 200 || res.status === "200"){

                    const FileDownload = require('js-file-download');

                    toastFunction(i18n.translate("download_started"),"success");
                    setState({
                        modalReport:false,
                        defaulValueConfigsModal:false,
                        optionDefaulValueConfigs:null,
                        modalContent: ""
                    });

                    FileDownload(res.data, nameOfDocument+'.xlsx');

                }else{

                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");   

                }
            }).catch((error) =>{
                catchErrorAjax(error, setState);
            }); 
        } 
    } 
} 

export const handleKeyDown = (e) => {
    // Verifica si la combinación es Ctrl+V
    if (e.ctrlKey && e.key === 'v') {
        // Permite el comportamiento predeterminado para Ctrl+V
        return;
    }

    // Permite la tecla Tab sin modificar el comportamiento
    if (e.key === 'Tab' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        return;
    }

    // Permite las teclas numéricas, Backspace y Delete
    if (/^\d$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Control') {
        return;
    }

    // Evita el comportamiento predeterminado para otras teclas
    e.preventDefault();
};

export const handlePaste = (e) => {
    e.preventDefault(); // Evita el comportamiento de pegado por defecto

    const text = e.clipboardData.getData('text/plain'); // Obtiene el texto del portapapeles
    const onlyDigits = text.replace(/[^\d]/g, ''); // Filtra solo dígitos

    const target = e.target; // Obtiene el input objetivo
    const { selectionStart, selectionEnd, value } = target; // Obtiene la posición del cursor y el valor actual

    // Construye el nuevo valor para el input
    const newValue = value.substring(0, selectionStart) + onlyDigits + value.substring(selectionEnd);

    // Actualiza el valor del input directamente
    target.value = newValue;
    
    // Establece la posición del cursor después del texto pegado
    target.setSelectionRange(selectionStart + onlyDigits.length, selectionStart + onlyDigits.length);

    // Si hay un manejador de eventos onChange, invócalo manualmente
    const event = new Event('input', { bubbles: true });
    target.dispatchEvent(event);
};


export const calculateScroll = (toRest) => {
    let nav = document.querySelector("#nav-top").offsetHeight;
    let navbar = document.querySelector(".navbar").offsetHeight;
    let altura = window.innerHeight - nav - navbar - toRest;
    let bodyContent = document.querySelector("#bodyContent");
    bodyContent.style.maxHeight = altura + "px";
} 

export const configNavContent = (selectOrgType, selectKeyConfig, getFeConfigs) => (
    <>
        <nav className='navbar'>
            <div className='contentInput row divIndex' style={{ width: '40%'}}>
                <div style={{ display: 'inline-block' }} className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <label>{i18n.translate("description")}</label>
                    {selectKeyConfig}
                </div>
                {' '}
                {selectOrgType} 
            </div>
            <div style={{ paddingTop: "22px" }}>
                <div style={{ display: 'inline-block' }} className="divIndex" onClick={() => getFeConfigs(false,true)}>
                    <button className='buttonMzateSecondary'>
                        <FaIcons.FaSearch />
                        <span>{i18n.translate("default_search")}</span>
                    </button>
                </div>
                {' '}
                <div style={{ display: 'inline-block' }} className="divIndex">
                    <button className='buttonMzateSecondary' onClick={() => getFeConfigs("search")}>
                        <FaIcons.FaSearch />
                        <span>{i18n.translate("default_show_all")}</span>
                    </button>
                </div>
                <div style={{ display: 'none' }} className="divShow">
                    <button className='buttonMzateSecondary' onClick={() => getFeConfigs("search",false)}>
                        <FaIcons.FaLongArrowAltLeft />
                        <span>{i18n.translate("default_back")}</span>
                    </button>
                </div>
            </div>
        </nav>
        <div></div>
    </>
) 

export const configIndexContent = (configs,prepareToShowConfig) =>  (
    <Table className='table table-hover divIndexTable'>
        <tbody>
            <tr>
                <th className='firstcell'>{i18n.translate("description")}</th>
                <th className='cellgroup'>{i18n.translate("values")}</th>
            </tr>
            {configs.map((con) => {
                return(
                    <tr className='cellOptions' key={con.key} onClick={() => prepareToShowConfig(con)} >
                        <td>
                            {!con.isVisible && <FaIcons.FaEyeSlash />}
                            {i18n.translate(con.key)}
                        </td>
                        <td className='text cellgroup'>
                            {con.companySettingType == "DYNAMIC_MAP" && 
                            <DynamicMap array={con} />}
                            {con.companySettingType == "ARRAY_STRING" && <ArrayString array={con} />}
                            {con.companySettingType == "INTERVAL_DAY_AND_HOUR" && <IntervalDayAndHour array={con} />}
                            {con.companySettingType == "BOOLEAN" && <Boolean array={con} />}
                            {con.companySettingType == "ARRAY_OF_MAP" && <ArrayOfMap array={con} />}
                            {con.companySettingType == "DYNAMIC_SELECTED_VALUES" && <DynamicSelectedValues array={con} />} 
                        </td>
                    </tr>
                )
            })} 
        </tbody>
    </Table>
)

export const configTemplateToUse = (config) => {
    let templateToUse = "";
    if (config.companySettingType == "DYNAMIC_MAP") {
        templateToUse = <DynamicMapTemplate array={config} />;
    } else if (config.companySettingType == "ARRAY_STRING") {
        templateToUse =  <ArrayTemplate array={config} />;
    } else if (config.companySettingType == "INTERVAL_DAY_AND_HOUR") {
        templateToUse = <IntervalDayAndHourTemplate array={config} />;
    } else if (config.companySettingType == "BOOLEAN") {
        templateToUse = <BooleanTemplate array={config} />;
    } else if(config.companySettingType == "ARRAY_OF_MAP"){
        templateToUse = <ArrayOfMapTemplate array={config} />;
    }else if(config.companySettingType == "DYNAMIC_SELECTED_VALUES"){
        templateToUse = <DynamicSelectedValuesTemplate array={config} />;
    } 
    return templateToUse; 
}

const getTypeSelect = (setState) => {
    let configurationOrgNodeId;
    if(document.querySelector(".modalBody .selectType").value == "1"){
        configurationOrgNodeId = document.querySelector(".modalBody #profileSelected").value;
        setState({optionSelectedDescription:document.querySelector(".modalBody #profileSelectedDescription").value})
    }else if(document.querySelector(".modalBody .selectType").value == "2"){
        configurationOrgNodeId = document.querySelector(".modalBody #groupSelected").value;
        setState({optionSelectedDescription:document.querySelector(".modalBody #groupSelectedDescription").value})
    }else{
        configurationOrgNodeId = document.querySelector(".modalBody #userSelected").value;
        setState({optionSelectedDescription:document.querySelector(".modalBody #userSelectedDescription").value})
    }
    return configurationOrgNodeId;
}

const getConfigsValues = (configuration,config,setState) => {
    configuration.key = config.key;
    configuration.functionalityGroup = config.functionalityGroup;
    configuration.id = null;
    configuration.companySettingType = config.companySettingType;
    configuration.tenantId = config.detail[0].tenantId;
    configuration.orgNodeId = getTypeSelect(setState);
    configuration.type = document.querySelector(".modalBody .selectType").value;
    configuration.username = "username";
    configuration.fullname = "fullname";
    return configuration;
}
const alertSelectType = () => {
    toastFunction(i18n.translate("select_org_node"),"warning");
}

const createNewConfigValue = (configuration,setState,getFunctionsConfigs) => {
    if(configuration.value == "" || configuration.value == undefined 
    || configuration.value == null){
        toastFunction(i18n.translate("value_cannot_be_empty"),"error");
    }else{
        DefaultAjax('').post("companySetting/companySetting",configuration)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result == 0 || result == "0"){
                    toastFunction(i18n.translate("configuration_created"),"success");
                    setState(prevState => ({
                        config: {
                            ...prevState.config,
                            show: false
                        },
                        modalContent:"",
                        html: "",
                        modalInsertar: false
                    }))
                    getFunctionsConfigs(true,true);
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            catchErrorAjax(error, setState);
        });
    } 
} 

export const validateConfigToCreate = (config,setState,getFunctionsConfigs) => {
    let configuration = {}; 
     const validSettingsType = ["BOOLEAN", "DYNAMIC_SELECTED_VALUES"];
     const isValid = validSettingsType.includes(config.companySettingType) 
    if (isValid) {
        if (!config.update) {
            if(document.querySelector(".modalBody .selectType").value == ""){
                alertSelectType();
            }else{
                configuration.value = document.querySelector(".modalBody .newValue").value;
                configuration = getConfigsValues(configuration,config,setState);
                if(configuration.orgNodeId == ""){
                    alertSelectType();
                }else{
                    createNewConfigValue(configuration,setState,getFunctionsConfigs);
                    setState({configurationPostSave:configuration})
                }
            }
        }
    }
} 

export const getValuesForSearchConfig = () => {
    let orgNodeId;
    const selectType = document.querySelector(".contentInput .selectType");
    const profileSelected = document.querySelector(".contentInput #profileSelected");
    const groupSelected = document.querySelector(".contentInput #groupSelected");
    const userSelected = document.querySelector(".contentInput #userSelected");
  
    const type = selectType.value;
    
    switch (type) {
      case '1':
        orgNodeId = profileSelected.value;
        break;
      case '2':
        orgNodeId = groupSelected.value;
        break;
      case '3':
        orgNodeId = userSelected.value;
        break;
      default:
        orgNodeId = '';
    }
  
    return { orgNodeId, type };
};

export const fetchConfigs = (orgNodeId, type, key, functionalityGroup) => {
    return DefaultAjax('')
        .get(`companySetting/companySettings?isSupportImpersonation=false&assignedId=${orgNodeId}&key=${key}&type=${type}&functionalityGroup=${functionalityGroup}`)
        .then(res => {
            if (res) {
                return JSON.parse(res.data);
            }
            throw new Error('No response data');
        });
};

export const processConfigs = (result, handleChange, moduleCode) => {
    let optionsKey = [];
    optionsKey.push({ value: "", label: i18n.translate("default_all") });
    for (let i = 0; i < result.length; i++) {
        optionsKey.push({ value: result[i].key, label: i18n.translate(result[i].key) });
    }

    let selectOrgType = <SelectGIE moduleCode={moduleCode} classSelect={"col-sm-12 col-md-4 col-lg-4 col-xl-4 selectGIE"} classSelectType={"col-sm-12 col-md-4 col-lg-4 col-xl-4"} />;
    let selectKeyConfig = <Select onChange={handleChange} options={optionsKey} defaultValue={{ label: optionsKey[0].label, value: optionsKey[0].value }} />;

    return { selectOrgType, selectKeyConfig };
};


export const updateStateAfterFetch = (isSearch, result, setState, selectOrgType, selectKeyConfig, prepareToShowConfig, getFeConfigs) => {
    const stateUpdate = {
        spinner: "",
        configs: result,
        configIndexContent: configIndexContent(result, prepareToShowConfig),
    };

    if (!isSearch) {
        stateUpdate.configNavContent = configNavContent(selectOrgType, selectKeyConfig, getFeConfigs);
    }

    setState(stateUpdate);

    setTimeout(() => {
        calculateScroll(50);
    }, 20);
};


export const handleError = (error, setState) => {
    catchErrorAjax(error, setState);
};

   

